@font-face {
  font-family: "Poppins"; /* Replace with your desired font name */
  src: url("./assets/fonts/Poppins-Regular.ttf") format("truetype"); /* Adjust file path and format */
  font-weight: normal;
  font-style: normal;
}

.App {
  /* text-align: center; */
  padding: 0;
  color: white;
  font-family: Poppins;
  padding-left: 50px;
  padding-right: 20px;
}

.Platform_data {
  padding: 1rem;
  background: #313131;
  border-radius: 1rem 1rem;
  display: grid;
}

.Data_Grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(175px, 4fr));
  height: 100%;
  max-height: 250px;
  overflow: auto;
  scrollbar-width: 3px;
}

.Data_Grid::-webkit-scrollbar {
  width: 5px;
  background: #515151;
}

.Data_Grid::-webkit-scrollbar-thumb {
  width: 5px;
  background: #a8fcc8;
}

.loading-bar {
  height: 5px;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin-left: 80px;
  z-index: 1;
}

.loading-bar .progress {
  height: 100%;
  background-color: #a8fcc8;
  width: 0;
  transition: width 1s ease-in-out;
}

.homepage_data {
  display: flex;
  width: 100%;
  gap: 1rem;
}

@media (max-width: 762px) {
  .homepage_data {
    flex-wrap: wrap;
  }
}
