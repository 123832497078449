.Navbar {
  background: #151617;
  color: white;
  text-align: center;
  display: grid;
  align-content: first baseline;

  height: 100%;
  max-height: 100dvh;
  min-height: 100dvh;

  position: fixed;
  left: 0;
  top: 0px;
  bottom: 0;
  overflow: hidden;
  gap: 1rem;
  z-index: 2;
}

.Logo {
  display: flex;
  align-items: center;
  gap: 1rem;
  text-transform: uppercase;
  font-family: sans-serif;
  font-weight: bolder;
  font-size: 1.2rem;
  padding: 1rem;
}

.Navbar_button {
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  height: 40px;
  border: #a8fcc8 1px solid;
  background: #313131;
  border-radius: 1rem 1rem;
  padding: auto;
  width: 50%;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
}
